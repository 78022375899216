import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
const ethereumChainID = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID);
const polygonChainID = parseInt(process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID);
export const injected = new InjectedConnector({
    // supportedChainIds: [1, 3, 4, 5, 42, 137, 80002],
    supportedChainIds: [ethereumChainID, polygonChainID],
});
export const walletlink = new WalletLinkConnector({
    url: process.env.NEXT_PUBLIC_ETH_JSONRPC_URL,
    appName: 'NFTON',
    // supportedChainIds: [1, 3, 4, 5, 42, 10, 137, 69, 420, 80002],
    supportedChainIds: [ethereumChainID, polygonChainID],
});
export async function generateWalletLink(blockChainValue) {
    let walletLink;
    if (blockChainValue === 'ethereum') {
        walletLink = new WalletLinkConnector({
            url: process.env.NEXT_PUBLIC_ETH_JSONRPC_URL,
            appName: 'NFTON',
            supportedChainIds: [ethereumChainID],
        });
    } else {
        walletLink = new WalletLinkConnector({
            url: process.env.NEXT_PUBLIC_POLYGON_ETH_JSONRPC_URL,
            appName: 'NFTON',
            supportedChainIds: [polygonChainID],
        });
    }
    return walletLink;
}
export function useEagerConnect() {
    const { activate, active, chainId } = useWeb3React();
    const [tried, setTried] = useState(false);
    const cookies = new Cookies();
    useEffect(() => {
        const blockChain = chainId === polygonChainID ? 'polygon' : 'ethereum';
        const generatedWalletLink = generateWalletLink(blockChain);
        const cookieValues = cookies.get('tokens');
        if (cookieValues) {
            if (cookieValues.walletType == 'coinbase') {
                activate(generatedWalletLink, undefined, true).catch(() => {
                    setTried(true);
                });
            } else {
                injected.isAuthorized().then((isAuthorized) => {
                    if (isAuthorized) {
                        activate(injected, undefined, true).catch(() => {
                            setTried(true);
                        });
                    } else {
                        setTried(true);
                    }
                });
            }
        }
    }, []); // intentionally only running on mount (make sure it's only mounted once :))

    // if the connection worked, wait until we get confirmation of that to flip the flag
    useEffect(() => {
        if (!tried && active) {
            setTried(true);
        }
    }, [tried, active]);
    return tried;
}
export function useInactiveListener(suppress = false) {
    const { active, error, activate, deactivate, chainId } = useWeb3React();
    const cookies = new Cookies();
    const blockChain = chainId === polygonChainID ? 'polygon' : 'ethereum';
    const generatedWalletLink = generateWalletLink(blockChain);
    useEffect(() => {
        const { ethereum } = window;
        const cookieValues = cookies.get('tokens');
        if (cookieValues) {
            if (ethereum && ethereum.on && !active && !error && !suppress) {
                const handleConnect = () => {
                    activate(
                        cookieValues.walletType == 'coinbase'
                            ? generatedWalletLink
                            : injected
                    );
                };
                const handleChainChanged = (chainId) => {
                    activate(
                        cookieValues.walletType == 'coinbase'
                            ? generatedWalletLink
                            : injected
                    );
                };
                const handleAccountsChanged = (accounts) => {
                    activate(
                        cookieValues.walletType == 'coinbase'
                            ? generatedWalletLink
                            : injected
                    );
                };
                const handleNetworkChanged = (networkId) => {
                    activate(
                        cookieValues.walletType == 'coinbase'
                            ? generatedWalletLink
                            : injected
                    );
                };

                ethereum.on('connect', handleConnect);
                ethereum.on('chainChanged', handleChainChanged);
                // ethereum.on('accountsChanged', handleAccountsChanged)
                ethereum.on('networkChanged', handleNetworkChanged);

                return () => {
                    if (ethereum.removeListener) {
                        ethereum.removeListener('connect', handleConnect);
                        ethereum.removeListener(
                            'chainChanged',
                            handleChainChanged
                        );
                        // ethereum.removeListener('accountsChanged', handleAccountsChanged)
                        ethereum.removeListener(
                            'networkChanged',
                            handleNetworkChanged
                        );
                    }
                };
            }
        }
    }, [active, error, suppress, activate]);
}

import { makeRequest } from '..';
import Interceptors from '../interceptors';

// export const getNounce = async (pub_address, wallet_provider) => {
//   const data = await makeRequest({
//     url: `users`,
//     params: {
//       public_address: pub_address,
//       wallet_type: wallet_provider,
//     },
//     method: "get",
//     external: false,
//   });

//   return data;
// };

export const authenticate = async (pub_address, signature) => {
    const data = await makeRequest({
        url: `users/authentication`,
        data: {
            public_address: pub_address,
            signature: signature,
        },
        method: 'post',
    });

    return data;
};

export const refreshToken = async () => {
    const data = await makeRequest({
        url: `users/refresh`,
        method: 'post',
    });

    return data;
};
let interceptores = new Interceptors();
export const getNounce = async (
    pub_address,
    wallet_provider,
    referall_link
) => {
    return interceptores.GET(
        `users?public_address=${pub_address}&wallet_type=${wallet_provider}&referall_link=${referall_link}`
    );
};
export const AuthenticationFromInterceptor = (
    pub_address,
    signature,
    wallet_type
) => {
    let req = {
        public_address: pub_address,
        signature: signature,
        wallet_type,
    };
    return interceptores.POST(`users/authentication`, req);
};
// export const CreateWallet = (data) => {

//   return interceptores.POST(`users/create-wallet`, data);
// }

export const CreateWallet = (req) => {
    return interceptores.POST(`users/create-wallet`, req);
};

export const loginWallet = (req) => {
    return interceptores.POST(`users/login-wallet`, req);
};

export const approvedFromMetamask = (req) => {
    return interceptores.POST(`nft/isApproved-from-metamask`, req);
};

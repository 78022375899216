import axios from 'axios';

export async function makeRequest({
    url,
    method,
    data,
    withCredentials,
    token,
    headers,
    params,
    external = false,
}) {
    let authHeader = token ? { Authorization: token } : {};
    try {
        const response = await axios({
            url: !external
                ? `${process.env.NEXT_PUBLIC_API_URL}/api/v1/${url}`
                : `${url}`,
            method: method,
            withCredentials,
            data: data,
            params: params,
            timeout: 120000,
            headers: {
                ...authHeader,
                ...headers,
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        });

        return response;
    } catch (error) {
        return error;
    }
}

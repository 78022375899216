import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import UseUtil from '../../Contexts/Util/useUtil';
import {
    injected,
    useEagerConnect,
    useInactiveListener,
    walletlink,
} from '../../Hooks/connectors';
import SwitchNetwork from '../../Hooks/SwitchNetwork';
import {
    AuthenticationFromInterceptor,
    getNounce,
} from '../../lib/Authentication';
import Model from '../Model';
import Button from '../Button';
import { Label } from '../FormsElements/utils';
import Input from '../FormsElements/Input';
import TextArea from '../FormsElements/textArea';
import Group from '../FormsElements/Group';
import SelectInput from '../FormsElements/Select';
import styles from './styles.module.scss';
import Script from 'next/script';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { CreateWallet } from '../../lib/Authentication';
import { loginWallet, approvedFromMetamask } from '../../lib/Authentication';
import getChainID from '../../Hooks/getChainID';
import blockChainData from '../../Hooks/blockChainData';
import { Contract } from '@ethersproject/contracts';
import contract from '../../Configs/Contract';
import { Modal } from 'react-bootstrap';
import { nftCartList, updateDeviceId, getAllNFTLit } from '../../lib/NFT';
import { getDeviceId } from '../../Hooks/getDeviceId';
import useNFT from '../../Contexts/NFT/useNFT';
import ReplaceCartModal from '../CartComponent/ReplaceCartModal';
import UseAuth from '../../Contexts/Auth/useAuth';

const CryptoJS = require('crypto-js');

const ConnectWalletModel = (props) => {
    const { setWalletType, showWrongNetworkPopup, hideWrongNetworkPopup } =
        UseUtil();
    const [checked, setChecked] = useState(false);
    const [blockChain, setBlockChain] = useState('ethereum');
    const [showErrorMsg, setShowErrorMessage] = React.useState(false);
    const [isCreateNewWallet, setIsCreateNewWallet] = useState(false);
    const [isWalletExist, setIsWalletExist] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState('');
    const { getExploreData, updateExplore, updateNftData, updateCartListData } =
        useNFT();
    const { authState } = UseAuth();

    const context = useWeb3React();
    const router = useRouter();
    const { link } = router.query;
    const {
        connector,
        library,
        account,
        activate,
        deactivate,
        active,
        error,
        chainId,
    } = context;
    const ethereumChainID = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID);
    const polygonChainID = parseInt(process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID);
    const [activatingConnector, setActivatingConnector] = React.useState();
    const [privateKey, setPrivateKey] = useState();
    const [phraseKey, setPhraseKey] = useState();
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [replaceCartModal, setReplaceCartModal] = useState(false);
    const [tokens, setTokens] = useState([]);
    const [blockChainReplace, setBlockchainReplace] = useState('');

    const [formData, setFormData] = useState({
        selectType: 'Private Key',
        privateKey: '',
        secretKey: '',
    });
    const [publicKey, setPublicKey] = useState();
    const [errors, setErrors] = useState({});
    const [isCopied, setIsCopied] = useState(false);
    const [isCopiedSecretKey, setIsCopiedSecretKey] = useState(false);
    React.useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);
    const getErrorMessage = (error) => {
        if (error instanceof NoEthereumProviderError) {
            return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.';
        } else if (error instanceof UnsupportedChainIdError) {
            return "You're connected to an unsupported network.";
        } else if (error instanceof UserRejectedRequestErrorInjected) {
            return 'Please authorize this website to access your Ethereum account.';
        } else {
            return 'Wallet connection failed!';
        }
    };
    useEffect(() => {
        if (error && !props?.authData?.isAuthenticated) {
            Swal.fire({
                icon: 'info',
                // title: "Hi",
                text: getErrorMessage(error),
                timer: 3500,
            });
            setChecked(false);
        }
    }, [error]);
    const handleExploreApicall = async () => {
        const ParamsExplore = {
            page_no: 0,
            page_size: 12,
            device_id: getDeviceId(),
        };

        let responseExplore = await getAllNFTLit(ParamsExplore);
        if (responseExplore?.status === 200) {
            getExploreData(responseExplore.data);
            updateNftData(responseExplore.data?.data);
        }
    };

    const updateDeviceIdAPI = async (data) => {
        const res = await updateDeviceId(data);
        if (res?.status === 500) {
            setReplaceCartModal(true);
            setBlockchainReplace(res?.data?.blockchain);
            setTokens(res?.data?.tokenIds);
            handleExploreApicall();
            const req = {
                device_id: getDeviceId(),
            };
            const response = await nftCartList(req);
            localStorage.setItem('cartLength', response?.data?.data?.length);
            updateCartListData(response?.data?.data);
        } else {
            handleExploreApicall();
            const req = {
                device_id: getDeviceId(),
            };
            const response = await nftCartList(req);
            localStorage.setItem('cartLength', response?.data?.data?.length);
            updateCartListData(response?.data?.data);
        }
    };

    useEffect(() => {
        (async () => {
            if (library && !props?.authData?.isAuthenticated) {
                try {
                    const signer = library.getSigner();
                    const isCoinbase = library.provider.isCoinbaseWallet;
                    const result = await getNounce(
                        account,
                        isCoinbase ? 'coinbase' : 'metamask',
                        link
                    );
                    let isSignup =
                        result?.data?.data?.is_signup === 1 ? true : false;
                    if (isSignup) {
                        const script = `
              rdt('track', 'SignUp', {
                "public_address" : ${account}
            });
          `;
                        <Script
                            strategy="lazyOnload"
                            dangerouslySetInnerHTML={{ __html: script }}
                        />;
                    } else {
                        const script = `
              rdt('track', 'Login', {
                "public_address" : ${account}
            });
          `;
                        <Script
                            strategy="lazyOnload"
                            dangerouslySetInnerHTML={{ __html: script }}
                        />;
                    }
                    if (result) {
                        const signature = await signer.signMessage(
                            `I am signing my one-time nonce: ${result.data.data.nonce}`
                        );
                        const user = await AuthenticationFromInterceptor(
                            account,
                            signature,
                            isCoinbase ? 'coinbase' : 'metamask'
                        );
                        if (user.status === 200) {
                            props.loginEvent(user.data.data);
                            props.setAuthCookies({
                                accessToken: user.data.auth.access_token,
                                refreshToken: user.data.auth.refresh_token,
                                walletType: isCoinbase
                                    ? 'coinbase'
                                    : 'metamask',
                                multipleWallet: true,
                            });
                            localStorage.setItem(
                                'userData',
                                JSON.stringify(user.data.data)
                            );
                            setWalletType(isCoinbase ? 'coinbase' : 'metamask');

                            if (user?.data?.data?.is_custom === 0) {
                                let chainIDOfNFT;
                                let getBlockChainDataValue;
                                if (
                                    chainId ===
                                        parseInt(
                                            process.env.NEXT_PUBLIC_CHAIN_ID
                                        ) &&
                                    user?.data?.data?.is_eth_approved === 2
                                ) {
                                    setShowApproveModal(true);
                                    chainIDOfNFT = getChainID('ethereum');
                                    if (chainIDOfNFT) {
                                        getBlockChainDataValue =
                                            blockChainData(chainIDOfNFT);

                                        let mycon = new Contract(
                                            contract[
                                                getBlockChainDataValue.contractAddress
                                            ],
                                            contract[
                                                getBlockChainDataValue.contractABIKey
                                            ],
                                            library.getSigner()
                                        );
                                        let data = await mycon
                                            .setApprovalForAll(
                                                contract[
                                                    getBlockChainDataValue
                                                        .contractAddressMultiple
                                                ],
                                                true
                                            )
                                            .then(async (data) => {
                                                const req = {
                                                    blockchain: 'ethereum',
                                                };
                                                const res =
                                                    await approvedFromMetamask(
                                                        req
                                                    );
                                                setShowApproveModal(false);

                                                const reqs = {
                                                    device_id: getDeviceId(),
                                                };
                                                const response =
                                                    await nftCartList(reqs);
                                                const tokens = [];
                                                if (response?.status === 200) {
                                                    response?.data?.data?.map(
                                                        (item) => {
                                                            return tokens.push(
                                                                item?.token
                                                            );
                                                        }
                                                    );
                                                    const data = {
                                                        device_id:
                                                            getDeviceId(),
                                                        token: tokens,
                                                    };
                                                    updateDeviceIdAPI(data);
                                                }
                                            });
                                    }
                                } else if (
                                    user?.data?.data?.is_matic_approved === 2
                                ) {
                                    setShowApproveModal(true);
                                    chainIDOfNFT = getChainID('MATIC');
                                    if (chainIDOfNFT) {
                                        getBlockChainDataValue =
                                            blockChainData(chainIDOfNFT);
                                        let mycon = new Contract(
                                            contract[
                                                getBlockChainDataValue.contractAddress
                                            ],
                                            contract[
                                                getBlockChainDataValue.contractABIKey
                                            ],
                                            library.getSigner()
                                        );
                                        let data = await mycon
                                            .setApprovalForAll(
                                                contract[
                                                    getBlockChainDataValue
                                                        .contractAddressMultiple
                                                ],
                                                true
                                            )
                                            .then(async (data) => {
                                                const req = {
                                                    blockchain: 'polygon',
                                                };
                                                const res =
                                                    await approvedFromMetamask(
                                                        req
                                                    );
                                                setShowApproveModal(false);

                                                const reqs = {
                                                    device_id: getDeviceId(),
                                                };
                                                const response =
                                                    await nftCartList(reqs);
                                                const tokens = [];
                                                if (response?.status === 200) {
                                                    response?.data?.data?.map(
                                                        (item) => {
                                                            return tokens.push(
                                                                item?.token
                                                            );
                                                        }
                                                    );
                                                    const data = {
                                                        device_id:
                                                            getDeviceId(),
                                                        token: tokens,
                                                    };
                                                    updateDeviceIdAPI(data);
                                                }
                                            });
                                    }
                                }
                            }
                            handleExploreApicall();
                            Swal.fire({
                                icon: 'success',
                                title: 'Wallet connected successfully!',
                                showConfirmButton: false,
                                timer: 1500,
                            }).then(async (result) => {
                                const req = {
                                    device_id: getDeviceId(),
                                };
                                const response = await nftCartList(req);
                                const tokens = [];
                                if (response?.status === 200) {
                                    response?.data?.data?.map((item) => {
                                        return tokens.push(item?.token);
                                    });
                                    const data = {
                                        device_id: getDeviceId(),
                                        token: tokens,
                                    };
                                    updateDeviceIdAPI(data);
                                }
                            });
                            setChecked(false);
                        } else {
                            Swal.fire({
                                icon: 'warning',
                                text: `${user.data.message}`,
                                timer: 1500,
                            });
                            setChecked(false);
                        }
                    }
                } catch (err) {
                    deactivate();
                    setChecked(false);
                }
            }
        })();
    }, [library]);

    // Metamsk Wallet Connect Start
    // Check and connect metamsk with selected blockchain
    const handleWalletConnection = async (wallet) => {
        const { ethereum } = window;
        await ethereum.request({
            method: 'wallet_requestPermissions',
            params: [
                {
                    eth_accounts: {},
                },
            ],
        });
        let networkChainID =
            blockChain === 'ethereum' ? ethereumChainID : polygonChainID;
        if (ethereum && ethereum.selectedProvider) {
            let params = [];
            networkChainID = await ethereum.request({
                method: 'eth_chainId',
                params,
            });
            networkChainID = parseInt(networkChainID, 16);
        }
        let isValidBlockChain = await SwitchNetwork(networkChainID, blockChain);
        resetWalletConnectForm();
        if (isValidBlockChain) {
            metaMaskWalletConnect();
        }
    };
    // Reset metamsk connect wallet form
    const resetWalletConnectForm = () => {
        setChecked(false);
        setBlockChain('ethereum');
    };
    // Metamask wallet connect
    const metaMaskWalletConnect = async () => {
        setActivatingConnector(injected);
        if (window.ethereum && window.ethereum.providers) {
            window.ethereum = window.ethereum.providers.find(
                (provider) => provider.isMetaMask
            );
        }
        activate(injected);
    };
    // Metamsk Wallet Connect End

    const handleWalletConnectionCoinbase = async (wallet) => {
        setChecked(false);
        setActivatingConnector(walletlink);
        activate(walletlink);
    };
    const triedEager = useEagerConnect();
    useInactiveListener(!triedEager || !!activatingConnector);

    const handleConnectCustomWallet = async () => {
        const req = {
            referall_link: link,
        };
        const user = await CreateWallet(req);
        if (user.status === 200) {
            props.onHide();
            setChecked(false);
            props.loginEvent(user.data.data);
            props.setAuthCookies({
                accessToken: user.data.auth.access_token,
                refreshToken: user.data.auth.refresh_token,
                walletType: 'customWallet',
                multipleWallet: true,
            });
            setIsCreateNewWallet(true);
            setPrivateKey(user.data.data.private_key);
            setPhraseKey(user.data.data.secret_phase);
            setPublicKey(user.data.data.public_address);
            localStorage.setItem('userData', JSON.stringify(user.data.data));
            setWalletType('customWallet');
            setChecked(false);
        } else {
            Swal.fire({
                icon: 'warning',
                text: `${user.data.message}`,
                timer: 1500,
            });
            setChecked(false);
        }
    };

    const formDataValidation = (data) => {
        let errors = {};
        if (!data.selectType || data.selectType === '') {
            errors.selectType = 'Please select Type';
        }
        if (data.selectType === 'Private Key') {
            if (!data.privateKey || data.privateKey === '') {
                errors.privateKey = 'Please enter private key';
            }
        } else if (data.selectType === '12 Word Secret Phrase') {
            if (!data.secretKey || data.secretKey === '') {
                errors.secretKey = 'Please enter secret key';
            }
        }
        return {
            errors,
            isValid: Object.keys(errors).length > 0 ? false : true,
        };
    };

    const handleContinueOnCreateWallet = () => {
        setIsCreateNewWallet(false);
        router.push(`/profile/${publicKey}?tab=created`);
    };

    const handleContinue = async () => {
        const { errors, isValid } = formDataValidation(formData);
        setErrors(errors);
        if (isValid) {
            const privateKey =
                formData.privateKey !== ''
                    ? CryptoJS.AES.encrypt(
                          formData.privateKey,
                          'secret'
                      ).toString()
                    : '';
            const secretKey =
                formData.secretKey !== ''
                    ? CryptoJS.AES.encrypt(
                          formData.secretKey,
                          'secret'
                      ).toString()
                    : '';
            const req = {
                key: privateKey,
                secret_phase: secretKey,
                referall_link: link,
            };
            const user = await loginWallet(req);
            if (user.status === 200) {
                props.onHide();
                setIsWalletExist(false);
                setChecked(false);
                props.loginEvent(user.data.data);
                props.setAuthCookies({
                    accessToken: user.data.auth.access_token,
                    refreshToken: user.data.auth.refresh_token,
                    walletType: 'customWallet',
                    multipleWallet: true,
                });
                localStorage.setItem(
                    'userData',
                    JSON.stringify(user.data.data)
                );
                setWalletType('custom');
                handleExploreApicall();
                Swal.fire({
                    icon: 'success',
                    title: 'Wallet connected successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                }).then(async (result) => {
                    const req = {
                        device_id: getDeviceId(),
                    };
                    const response = await nftCartList(req);
                    const tokens = [];
                    if (response?.status === 200) {
                        response?.data?.data?.map((item) => {
                            return tokens.push(item?.token);
                        });
                        const data = {
                            device_id: getDeviceId(),
                            token: tokens,
                        };
                        updateDeviceIdAPI(data);
                    }
                });
                setChecked(false);
                setFormData({
                    selectType: 'Private Key',
                    privateKey: '',
                    secretKey: '',
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: `${user.data.message}`,
                    timer: 1500,
                });
                setChecked(false);
                props.onHide();
                setIsWalletExist(false);
                setChecked(false);
                setFormData({
                    selectType: 'Private Key',
                    privateKey: '',
                    secretKey: '',
                });
            }
        }
    };

    const onChangeFormValue = (e, name) => {
        if (name === 'selectType') {
            setFormData({
                ...formData,
                selectType: e,
            });
        } else {
            setFormData({
                ...formData,
                [name]: e.target.value,
            });
        }
    };

    const onCopyValue = async (key, bit) => {
        const bytes = CryptoJS.AES.decrypt(key, 'secret');
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            navigator.clipboard.writeText(originalText);
            if (bit === 1) {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            } else {
                setIsCopiedSecretKey(true);
                setTimeout(() => {
                    setIsCopiedSecretKey(false);
                }, 2000);
            }
        } else {
            // text area method
            let textArea = document.createElement('textarea');
            textArea.value = originalText;
            // make the textarea out of viewport
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            await new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
            if (bit === 1) {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            } else {
                setIsCopiedSecretKey(true);
                setTimeout(() => {
                    setIsCopiedSecretKey(false);
                }, 2000);
            }
        }
    };

    const callUpdateCartAPI = async () => {
        const reqs = {
            device_id: getDeviceId(),
        };
        const response = await nftCartList(reqs);
        const tokens = [];
        if (response?.status === 200) {
            response?.data?.data?.map((item) => {
                return tokens.push(item?.token);
            });
            const data = {
                device_id: getDeviceId(),
                token: tokens,
            };
            updateDeviceIdAPI(data);
        }
    };

    return (
        <>
            <Model
                visible={props.visible}
                title={'Connect your wallet'}
                className={`${styles.wallet_model}`}
                onHide={() => {
                    props.onHide();
                    setChecked(false);
                }}
                // modelClass={styles.modelStyle}
            >
                <div className={`${styles.chececkedButtonForm}`}>
                    <input
                        checked={checked}
                        onChange={() => {
                            setChecked(!checked);
                            setShowErrorMessage(false);
                        }}
                        type="checkbox"
                        style={{ appearance: 'auto' }}
                    />
                    <p>
                        By connecting your wallet, you agree to our
                        <Link
                            href={`${process.env.NEXT_PUBLIC_WEB_URL}/terms-of-services`}
                        >
                            <a> Terms of service</a>
                        </Link>{' '}
                        and{' '}
                        <Link
                            href={`${process.env.NEXT_PUBLIC_WEB_URL}/privacy-policy`}
                        >
                            <a> Privacy Policy</a>
                        </Link>
                        {showErrorMsg && (
                            <small className={`text-danger`}>
                                <br />
                                {`Please agree with our terms of service & privacy policy`}
                            </small>
                        )}
                    </p>
                </div>
                <div className={styles.wallet__btns}>
                    <button
                        onClick={() => {
                            if (checked === true) {
                                setShowErrorMessage(false);
                                handleWalletConnection('metamask');
                                props.onHide();
                            } else {
                                setShowErrorMessage(true);
                            }
                        }}
                        className={styles.btn}
                        // disabled={checked === true ? false : true}
                    >
                        Metamask{' '}
                        <span className={styles.ico}>
                            <img
                                src="/assets/vectors/metamask_wallet.png"
                                alt="metamask wallet"
                                width="20"
                                height="20"
                            />
                        </span>
                    </button>
                    <button
                        onClick={() => {
                            if (checked === true) {
                                setShowErrorMessage(false);
                                (async () => {
                                    handleWalletConnectionCoinbase('coinbase');
                                    props.onHide();
                                })();
                            } else {
                                setShowErrorMessage(true);
                            }
                        }}
                        className={styles.btn}
                        // disabled={checked === true ? false : true}
                    >
                        Coinbase{' '}
                        <span className={styles.ico}>
                            <img
                                src="/assets/vectors/coinbase_wallet.png"
                                alt="coinbase wallet"
                                //   width={"20px"}
                                //   height={"20px"}
                                //   layout={"responsive"}
                            />
                        </span>
                    </button>
                    <button
                        className={styles.btn}
                        onClick={() => {
                            if (checked === true) {
                                setIsWalletExist(!isWalletExist);
                                props.onHide();
                            } else {
                                setShowErrorMessage(true);
                            }
                        }}
                    >
                        I already have a wallet{' '}
                        <span className={styles.ico}>
                            <img
                                src="/assets/vectors/wallets.svg"
                                alt="coinbase wallet"
                                width="20"
                                height="20"
                                //   width={"20px"}
                                //   height={"20px"}
                                //   layout={"responsive"}
                            />
                        </span>
                    </button>
                    {/* <button onClick={() => { handleWalletConnectionCustomWallet('custom')}}>
          Custom wallet
        </button> */}
                    <Image
                        src="/assets/OR.svg"
                        height={50}
                        width={100}
                        className="m-1"
                    />
                    <div className={`${styles.newwalletbtn}`}>
                        <Button
                            varient={'primary'}
                            // className={` mt-3 p-3`}
                            className={`${styles.newwalletbtn}`}
                            onClick={() => {
                                handleConnectCustomWallet();
                            }}
                        >
                            Create a New Wallet
                        </Button>
                    </div>
                </div>
            </Model>

            <Model
                visible={isCreateNewWallet}
                // visible={true}
                title={'Your Blockchain Wallet'}
                className={`${styles.wallet_model}`}
                onHide={() => {
                    setIsCreateNewWallet(false);
                }}
                modelClass={styles.modelClass}
                titleClass={true}
            >
                <p className={`${styles.subTitle}`}>
                    We generated you a new Blockchain Wallet to store your NFTs
                </p>
                <Group>
                    <Label className={`label`} title={`Private Key`}></Label>
                    <div className="d-flex">
                        <Input
                            type={'text'}
                            value={
                                privateKey?.slice(0, 4) +
                                '***********************' +
                                privateKey?.substring(privateKey.length - 4)
                            }
                            id="title"
                            className={`${styles.spotlight_inputbox}`}
                            placeHolder="Private Key"
                            onChange={(e) => {
                                setPrivateKey(e.target.value);
                            }}
                            disable={true}
                        />

                        <a
                            onClick={() => onCopyValue(privateKey, 1)}
                            className={`${styles.link_style}`}
                        >
                            <Image
                                src={
                                    isCopied
                                        ? '/assets/copied.svg'
                                        : '/assets/copy_icon.svg'
                                }
                                alt=""
                                height={24}
                                width={24}
                                className=""
                            />
                        </a>
                    </div>
                </Group>
                <Group>
                    <Label
                        className={`label`}
                        title={`12 Word Secret Phrase`}
                    ></Label>
                    <div className="d-flex">
                        <TextArea
                            bit={false}
                            type={'text'}
                            value={
                                phraseKey?.slice(0, 4) +
                                '***********************' +
                                phraseKey?.substring(phraseKey.length - 4)
                            }
                            // id="title"
                            className={`${styles.spotlight_inputbox_textarea}`}
                            placeHolder="12 Word Secret Phrase"
                            onChange={(e) => {
                                setPhraseKey(e.target.value);
                            }}
                            disable={true}
                        />
                        <a
                            onClick={() => onCopyValue(phraseKey, 2)}
                            className={`${styles.link_style}`}
                        >
                            <Image
                                src={
                                    isCopiedSecretKey
                                        ? '/assets/copied.svg'
                                        : '/assets/copy_icon.svg'
                                }
                                alt=""
                                height={24}
                                width={24}
                                className=""
                            />
                        </a>
                    </div>
                </Group>
                <div className={`${styles.new_wallet_sub}`}>
                    Your 12-word secret phrase is required when restoring your
                    wallet or transferring NFTs. Write down the secret phrase
                    and keep it somewhere safe. Do not share it with anyone. You
                    can also see it on the Profile page.
                </div>
                {/* <span className={`${styles.detailData}`}>Your 12-word secret phrase is required when restoring your wallet or transferring NFTs. Write down the secret phrase and keep it somewhere safe. Do not share it with anyone. You can also see it on the Profile page.</span> */}
                <div className="d-flex justify-content-center">
                    <Button
                        varient={'primary'}
                        className="mt-3 pl-4 pr-4"
                        onClick={() => {
                            handleContinueOnCreateWallet();
                        }}
                    >
                        Continue
                    </Button>
                </div>
            </Model>

            <Model
                visible={isWalletExist}
                title={'Import Wallet'}
                className={`${styles.wallet_model}`}
                onHide={() => {
                    setIsWalletExist(false);
                }}
            >
                {/* <div className={styles.wallet__btns}> */}
                <Group>
                    <Label className={`label`} title={`Select a Type`} />
                    <SelectInput
                        defaultSelectValue="Private Key"
                        options={['Private Key', '12 Word Secret Phrase']}
                        label={`Select a Type`}
                        placeHolder={'Select a Type'}
                        currentValue={formData.selectType}
                        onChangeHandler={(e) => {
                            onChangeFormValue(e, 'selectType');
                        }}
                    />
                    {errors.selectType && (
                        <small className={`text-danger pl-1`}>
                            {errors.selectType}
                        </small>
                    )}
                    {/* </div> */}
                </Group>
                {formData?.selectType === 'Private Key' && (
                    <Group>
                        <Label
                            className={`label`}
                            title={`Private Key`}
                        ></Label>
                        <Input
                            type={'text'}
                            value={formData.privateKey}
                            id="title"
                            className={`${styles.spotlight_inputbox}`}
                            placeHolder="Private Key"
                            onChange={(e) => {
                                onChangeFormValue(e, 'privateKey');
                            }}
                        />
                        {errors.privateKey && (
                            <small className={`text-danger pl-1`}>
                                {errors.privateKey}
                            </small>
                        )}
                    </Group>
                )}
                {formData?.selectType === '12 Word Secret Phrase' && (
                    <Group>
                        <Label
                            className={`label`}
                            title={`12 Word Secret Phrase`}
                        ></Label>
                        <TextArea
                            className={`${styles.spotlight_inputbox_textarea}`}
                            value={formData.secretKey}
                            type={'text'}
                            placeHolder="12 Word Secret Phrase"
                            onChange={(e) => {
                                onChangeFormValue(e, 'secretKey');
                            }}
                        />
                        {errors.secretKey && (
                            <small className={`text-danger pl-1`}>
                                {errors.secretKey}
                            </small>
                        )}
                    </Group>
                )}
                <div className={`d-flex justify-content-center p-2`}>
                    <Button
                        varient={'primary'}
                        className="mt-3 p-2"
                        onClick={() => {
                            handleContinue();
                        }}
                    >
                        Import
                    </Button>
                </div>
            </Model>

            <Model
                visible={showApproveModal}
                title={'Approve for multiple purchase'}
                className={`${styles.wallet_model}`}
                onHide={() => {
                    setShowApproveModal(false);
                    callUpdateCartAPI();
                }}
            >
                {/* <h1 className={`${styles.title}`}>Approve for multiple purchase</h1> */}
                <div className={`${styles.replace_separate}`}>
                    <p className={`${styles.replace_description}`}>
                        Please approve the new contract by clicking{' '}
                        <span style={{ color: '#7558BB', fontWeight: 'bold' }}>
                            {' '}
                            Confirm{' '}
                        </span>{' '}
                        to put your NFTs on the{' '}
                        <span style={{ color: '#7558BB', fontWeight: 'bold' }}>
                            Multiple purchase selection.
                        </span>
                    </p>
                </div>
            </Model>

            <ReplaceCartModal
                show={replaceCartModal}
                onHide={() => {
                    setReplaceCartModal(false);
                }}
                token={tokens}
                blockchain={blockChainReplace}
                // setIs_cart={2}
                // is_cart={is_cart}
                // bit={2}
            />
        </>
    );
};

export default ConnectWalletModel;
